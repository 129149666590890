<template>
  <div>
    <CSTabBar
        :tabbar="tabbar"
        :checkedTab="checkedTab"
        @changeTabBar="changeTabBar"
    />

    <div class="filter-panel">
      <input
          type="text"
          class="cs-input"
          v-model="queryParams.keyword"
          style="width: 160px"
          placeholder="搜索企业名称/联系人"
      />
      <CSSelect>
        <select v-model="queryParams.industry">
          <option value="">全部行业</option>
          <option
              v-for="(val, type) in INDUSTRY_TYPES"
              :key="type"
              :value="type"
          >
            {{ val }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.source">
          <option value="">全部客户来源</option>
          <option
              v-for="(val, type) in CUSTOMER_SOURCES"
              :key="type"
              :value="type"
          >
            {{ val }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.ownerId">
          <option value="">全部员工</option>
          <option
              v-for="(staff, index) in staffList"
              :key="`${staff.id}_${index}`"
              :value="staff.id"
          >
            {{ staff.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect v-show="checkedTab == 2">
        <select v-model="queryParams.state">
          <option :value="2">审核状态不限</option>
          <option :value="3">审核通过</option>
          <option :value="4">审核拒绝</option>
        </select>
      </CSSelect>
      <button
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="querySubscriptionCustomer()"
      >
        查询
      </button>
    </div>

    <div class="result-panel" v-if="checkedTab == 1">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>提交时间</th>
            <th>企业名称</th>
            <th>认租房间</th>
            <th>认租定金</th>
            <th>认租保留期</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>联系人</th>
            <th>电话</th>
            <th>所属员工</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template
              v-if="
              subscriptionCustomerList && subscriptionCustomerList.length > 0
            "
          >
            <tr
                v-for="customer in subscriptionCustomerList"
                :key="customer.leadId + '-' + customer.subscriptionId"
            >
              <td class="full-date-td">{{ customer.auditTime }}</td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 3, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
              </td>
              <td>
                <div
                    class="allow-click"
                    v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                    @click="lookIntentionRooms(customer.intentionRooms)"
                >
                  {{ customer.intentionRooms | getIntentionRoomNames }}
                </div>
                <template v-else> -</template>
              </td>
              <td>{{ customer.moneyAmount }}</td>
              <td>{{ customer.retentionDays }}</td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 3, customer.userId)
                  "
                >{{ customer.contactsName }}</span
                >
              </td>
              <td>
                {{ customer.contactsPhone }}
              </td>
              <td>{{ customer.userName || "-" }}</td>
              <td>
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 86px">
                    <li @click="openAuditCustomer(customer.subscriptionId)">
                      <a style="width: 100%">通过</a>
                    </li>
                    <li @click="openRejectCustomer(customer.subscriptionId)">
                      <a style="width: 100%">拒绝</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <div class="result-panel" v-if="checkedTab == 2">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>审核时间</th>
            <th>企业名称</th>
            <th>认租房间</th>
            <th>认租定金</th>
            <th>认租保留期</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>联系人</th>
            <th>电话</th>
            <th>所属员工</th>
            <th>审核状态</th>
            <th>是否签约</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template
              v-if="
              subscriptionCustomerList && subscriptionCustomerList.length > 0
            "
          >
            <tr
                v-for="customer in subscriptionCustomerList"
                :key="customer.leadId + '-' + customer.subscriptionId"
            >
              <td class="full-date-td">{{ customer.auditTime }}</td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 3, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
              </td>
              <td>
                <div
                    class="allow-click"
                    v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                    @click="lookIntentionRooms(customer.intentionRooms)"
                >
                  {{ customer.intentionRooms | getIntentionRoomNames }}
                </div>
                <template v-else> -</template>
              </td>
              <td>{{ customer.moneyAmount }}</td>
              <td>{{ customer.retentionDays }}</td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 3, customer.userId)
                  "
                >{{ customer.contactsName }}</span
                >
              </td>
              <td>
                {{ customer.contactsPhone }}
              </td>
              <td>{{ customer.userName || "-" }}</td>
              <td>
                <template
                    v-if="customer.auditComment && customer.auditComment !== ''"
                >
                  <span
                      class="allow-click"
                      @click="
                      lookSubscribeAuditComment(
                        customer.auditComment,
                        customer.auditState
                      )
                    "
                  >{{ auditState[customer.auditState] || "-" }}</span
                  >
                </template>
                <template v-else>
                  {{ auditState[customer.auditState] || "-" }}
                </template>
              </td>
              <td>
                {{ customer.signContract ? "已签约" : "未签约" }}
              </td>
              <td>
                <div class="btn-group" v-if="checkedTab == 2">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 86px">
                    <li
                        v-if="!customer.signContract"
                        @click="signCustomer(customer.subscriptionId, true)"
                    >
                      <a style="width: 100%">已签约</a>
                    </li>
                    <li
                        v-else
                        @click="signCustomer(customer.subscriptionId, false)"
                    >
                      <a style="width: 100%">未签约</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>

    <!-- 审核认租客户 -->
    <CSDialog
        :dialogWidth="780"
        :dialogVisible="rejectDialog.visible"
        @onClose="rejectDialog.onClose"
        @onConfirm="rejectDialog.onConfirm"
        dialog-title="驳回"
        dialogConfirmBtnText="确定"
    >
      <template v-slot:dialog-content>
        <div class="audit-dialog-content">
          <div class="form-panel">
            <div class="form-item">
              <div style="vertical-align: top; margin-right: 20px">
                驳回原因
              </div>
              <textarea
                  class="cs-input"
                  v-model="rejectDialog.comment"
                  placeholder="请输入"
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>
<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import {
  queryDepartmentStaffUrl,
  querySubscriptionsdUrl,
  auditSubscribeUrl,
  signLeadContractUrl,
  queryDepartmentUrl,
} from "@/requestUrl";
import {
  INDUSTRY_TYPES,
  CUSTOMER_SOURCES,
  ORG_LEVEL,
  DEPARTMENT_TYPES,
  JOB_LEVEL,
} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  created() {
    window.addEventListener("keydown", this.querySubscriptionCustomerDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.querySubscriptionCustomerDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.querySubscriptionCustomerDown);
  },
  name: "CustomerAudit",
  components: {
    CSTable,
    CSTabBar,
    CSSelect,
    CSDialog,
    Pagination,
  },
  data() {
    return {
      INDUSTRY_TYPES,
      CUSTOMER_SOURCES,
      filterHeight: 0,
      rejectDialog: {
        visible: false,
        comment: "",
        onClose: () => {
          this.rejectDialog.visible = false;
        },
        onConfirm: () => {
        },
      },
      tabbar: {
        1: {
          name: "待审核",
        },
        2: {
          name: "已审核",
        },
      },
      auditState: {
        0: "未认租",
        1: "待上级审核",
        2: "上级已拒绝",
        3: "待财务审核",
        4: "财务审核驳回",
        5: "上级已通过",
      },
      //  选中的tab
      checkedTab: 1,
      subscriptionCustomerList: [],
      queryParams: {
        regionCode: this.$vc.getCurrentRegion().code,
        keyword: "",
        source: "",
        industry: "",
        target: 2,
        ownerId: "",
        state: 1,
      },
      staffList: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  filters: {
    // 返回意向房间名称
    getIntentionRoomNames(rooms) {
      return rooms
          .map((room) => room.buildingName + "-" + room.roomName)
          .toString();
    },
  },
  async mounted() {
    this.querySubscriptionCustomer();
    const staffInfo = this.$vc.getCurrentStaffInfo();
    let departmentCode = staffInfo.departmentCode,
        staffList = [];
    if (
        [DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER].includes(
            staffInfo.dutyType
        ) &&
        staffInfo.jobLevel === JOB_LEVEL.SUPERVISOR
    ) {
      const departmentList = await this.queryDepartmentList(
          ORG_LEVEL.DEPARTMENT,
          1,
          100,
          staffInfo.companyCode
      );
      for (let val of departmentList) {
        if (val.dutyType == staffInfo.dutyType) {
          departmentCode = val.code;
          const groupList = await this.queryDepartmentList(
              ORG_LEVEL.GROUP,
              1,
              100,
              val.code
          );
          for (let group of groupList) {
            // staffList.push(...(await this.getStaffList(group.code)));
          }
          return;
        }
      }
    } else {
      const groupList = await this.queryDepartmentList(
          ORG_LEVEL.GROUP,
          1,
          100,
          departmentCode
      );
      for (let group of groupList) {
        // staffList.push(...(await this.getStaffList(group.code)));
      }
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
    }
    staffList.push(...(await this.getStaffList(departmentCode)));
    this.staffList = staffList;

    this.$vc.on(this.$route.path, "pagination_page", "event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.querySubscriptionCustomer(pageNo);
    });
  },
  methods: {
    querySubscriptionCustomerDown(e) {
      if (e.keyCode == 13) {
        this.querySubscriptionCustomer();
      }
    },
    /**
     * 查看审核评论
     * @param {String} comment 评论内容
     * @param {Number} status 审核状态
     * */
    lookSubscribeAuditComment(comment, status) {
      this.$CSDialog.alert({
        title: [2, 4].includes(status) ? "驳回原因" : "通过",
        message: comment,
      });
    },
    lookIntentionRooms(rooms) {
      let roomHtml = "";
      rooms.forEach((room) => {
        roomHtml += `<p>${room.buildingName}-${room.roomName}</p>`;
      });
      this.$CSDialog.alert({
        title: "认租房间",
        width: "400px",
        messageHtml: `
                    <div class="comment-panel text-center">
                        <div class="item text-center">
                            <div style="max-width: 100%;">
                                ${roomHtml}
                            </div>
                        </div>
                    </div>
                `,
      });
    },
    /**
     * 签约客户
     * @param {Number} subscriptionId 认租id
     * @param {Boolean} isSignContract 是否签约
     *  */
    signCustomer(subscriptionId, isSignContract) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定操作吗？",
        onConfirm: () => {
          this.$fly
              .post(signLeadContractUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                subscriptionId,
                isSignContract,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast(`${isSignContract ? "签约" : "取消签约"}成功`);
                this.$CSDialog.instance.closeDialog();
                this.querySubscriptionCustomer();
              });
        },
      });
    },
    /**
     * 前往客户详情页面
     * @param {Number} leadId 销售线索id
     * @param {Number} tabId 目标页面中的选中的tab
     * @param {Number} sourceType 客户池标识   1：公有客户池  2：私有客户池   3：认租客户池
     * @param {Number} staffId 员工id
     *  */
    toCustomerInfoPage(leadId, tabId, sourceType, staffId = 0) {
      this.$router.push({
        name: "customerInfo",
        params: {
          leadId,
          tabId,
          sourceType,
          staffId,
        },
      });
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {NUmber} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
        level = ORG_LEVEL.COMPANY,
        pageNo = 1,
        pageSize = 100,
        parentCode
    ) {
      return this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType: "",
            parentCode,
            level,
            pageNo,
            pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return [];
            }

            return res.data;
          });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      return this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return [];
            }
            return res.data;
          });
    },
    /**
     * 打开驳回审核客户弹窗
     * @param {Number} subscribeId 认租id
     *  */
    openRejectCustomer(subscriptionId) {
      this.rejectDialog.visible = true;
      this.rejectDialog.comment = "";
      this.rejectDialog.onConfirm = async () => {
        const result = await this.auditCustomerRequest(
            subscriptionId,
            false,
            this.rejectDialog.comment
        );
        if (result) {
          this.rejectDialog.onClose();
          this.$vc.toast("驳回成功");
          this.querySubscriptionCustomer();
        }
      };
    },
    /**
     * 打开审核客户弹窗
     * @param {Number} subscribeId 认租id
     *  */
    openAuditCustomer(subscribeId) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定通过吗？",
        onConfirm: async () => {
          const result = await this.auditCustomerRequest(subscribeId, true);
          if (result) {
            this.$vc.toast("审核通过");
            this.$CSDialog.instance.closeDialog();
            this.querySubscriptionCustomer();
          }
        },
      });
    },
    /**
     * 审核认租客户的请求
     * @param {Number} subscribeId 认租id
     * @param {Boolean} approve 审核结果 true：通过  flase：驳回
     * @param {String} comment 备注
     *  */
    auditCustomerRequest(subscribeId, approve, comment) {
      return this.$fly
          .post(auditSubscribeUrl, {
            subscribeId,
            approve,
            comment,
            regionCode: this.$vc.getCurrentRegion().code,
          })
          .then((res) => {
            if (res.code !== 0) {
              return false;
            }
            return true;
          });
    },
    /**
     * 更改选中的tab
     * @param {Number} id 选中的tab
     * @param {Object} checkedName 更改的属性名
     *  */
    changeTabBar(id, checkedName) {
      this[checkedName] = id;
      this.queryParams.state = +id;
      this.querySubscriptionCustomer();
    },
    // 查询认租客户
    querySubscriptionCustomer(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(querySubscriptionsdUrl, {
            ...this.queryParams,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: res.data.total ? pageSize : res.data.datas.length,
              });
            }
            this.subscriptionCustomerList = res.data.datas;
          });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './filter-panel.styl';

.audit-dialog-content {
  padding: 30px;
}

.form-item {
  font-size: 24px;

  &-label {
    margin-right: 20px;
    width: 144px;
    color: #444;
  }

  &:not(:last-of-type) {
    margin-bottom: 23px;
  }

  div {
    display: inline-block;
    vertical-align: middle;
    max-width: 720px;
  }

  .cs-select {
    display: flex;
  }

  textarea.cs-input {
    width: 600px;
    height: 200px;
    border: none;
    outline: none;
    resize: none;
    background: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
  }
}

.dropdown-menu {
  width: 48px;
  min-width: 48px;

  li {
    a {
      padding: 3px 6px;
    }
  }
}
</style>
